// src/components/KLA/KLAResult.js
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import './KLAResult.css';

function KLAResult() {
  const location = useLocation();
  const { inputText } = location.state || { inputText: '' };
  const [analysis, setAnalysis] = useState({
    info: {
      name: '',
      birthday: '',
      gender: '',
      note: '',
      examdate: '',
      minutes: 0,
      seconds: 0,
      time: 0,
    },

    mlu_w: [0, 0, 0, 0],             // MLU_w = (낱말빈도수 / 총발화수)
    mlu_m: [0, 0, 0, 0],             // MLU_m = (낱말빈도수 + 문법형태소빈도수 / 총발화수)
    num_gram_types: [0, 0, 0, 0],    // 문법형태소 유형 수
    num_gram_tokens: [0, 0, 0, 0],   // 문법형태소 빈도 수
    num_morp_types: [0, 0, 0, 0],
    num_morp_tokens: [0, 0, 0, 0],
    ratio_gram: [0, 0, 0, 0],
    ratio_morp: [0, 0, 0, 0],
    num_word_types: [0, 0, 0, 0],
    num_word_tokens: [0, 0, 0, 0],
    ratio_word: [0, 0, 0, 0],

    num_line_total: [0, 0, 0, 0],
    num_line_unknown: [0, 0, 0, 0],
    num_line_partial: [0, 0, 0, 0],
    num_line_known: [0, 0, 0, 0],

    num_line_end_period: [0, 0, 0, 0],
    num_line_end_question: [0, 0, 0, 0],
    num_line_end_exclamation: [0, 0, 0, 0],
    num_line_end_bracket: [0, 0, 0, 0],
    num_line_end_exponential: [0, 0, 0, 0],

    statements: [[], [], []],
    statements_understand: [[], [], []],
    word_count: [[], [], []],

    wordlist: {},
    wordlist_count: {},

    grams_order_max: 0,
    grams_order: [[], [], []],
    grams_order_count: [[], [], []],

    tags: [],
    lineByTag: {},
  });
  
  const [isInfoCollapsed, setIsInfoCollapsed] = useState(false);
  const [isGrammarCollapsed, setIsGrammarCollapsed] = useState(false);
  const [isChildStatementsCollapsed, setIsChildStatementsCollapsed] = useState(false);
  const [isEojeolCollapsed, setIsEojeolCollapsed] = useState(false);
  const [isWordListCollapsed, setIsWordListCollapsed] = useState(false);
  const [isGrammarMorphemeListCollapsed, setIsGrammarMorphemeListCollapsed] = useState(false);
  const [isStapleListCollapsed, setIsStapleListCollapsed] = useState(false);
  const [isFluencyCollapsed, setIsFluencyCollapsed] = useState(false);

  const toggleInfoCollapse = () => {
    setIsInfoCollapsed(!isInfoCollapsed);
  };

  const toggleGrammarCollapse = () => {
    setIsGrammarCollapsed(!isGrammarCollapsed);
  };

  const toggleChildStatementsCollapse = () => {
    setIsChildStatementsCollapsed(!isChildStatementsCollapsed);
  };

  const toggleEojeolCollapse = () => {
    setIsEojeolCollapsed(!isEojeolCollapsed);
  };

  const toggleWordListCollapse = () => {
    setIsWordListCollapsed(!isWordListCollapsed);
  };

  const toggleGrammarMorphemeListCollapse = () => {
    setIsGrammarMorphemeListCollapsed(!isGrammarMorphemeListCollapsed);
  };

  const toggleStapleListCollapse = () => {
    setIsStapleListCollapsed(!isStapleListCollapsed);
  };

  const toggleFluencyCollapse = () => {
    setIsFluencyCollapsed(!isFluencyCollapsed);
  };

  const analyzeText = useCallback((text) => {

    let result = {
      //------------------------------------------------------------------------
      // 아동 정보
      info: {
        name: "",
        birthday: "",
        gender: "",
        note: "",
        examdate: "",
        time_str: "",
        minutes: 0,
        seconds: 0,
        time: 0
      },

      mlu_w: [0, 0, 0, 0],             // MLU_w = (낱말빈도수 / 총발화수)
      mlu_m: [0, 0, 0, 0],             // MLU_m = (낱말빈도수 + 문법형태소빈도수 / 총발화수)
      num_gram_types: [0, 0, 0, 0],   // 문법형태소 유형 수
      num_gram_tokens: [0, 0, 0, 0],  // 문법형태소 빈도 수
      num_morp_types: [0, 0, 0, 0],   
      num_morp_tokens: [0, 0, 0, 0],
      ratio_gram: [0, 0, 0, 0],
      ratio_morp: [0, 0, 0, 0],
      num_word_types: [0, 0, 0, 0],
      num_word_tokens: [0, 0, 0, 0],
      ratio_word: [0, 0, 0, 0],

      //------------------------------------------------------------------------
      // 이해가능도별 분석
      num_line_total: [0, 0, 0, 0],
      num_line_unknown: [0, 0, 0, 0],
      num_line_partial: [0, 0, 0, 0],
      num_line_known: [0, 0, 0, 0],

      //------------------------------------------------------------------------
      // 이해가능 발화 유형 분석
      num_line_end_period: [0, 0, 0, 0],
      num_line_end_question: [0, 0, 0, 0],
      num_line_end_exclamation: [0, 0, 0, 0],
      num_line_end_bracket: [0, 0, 0, 0],
      num_line_end_exponential: [0, 0, 0, 0],

      statements: [[], [], []],
      statements_understand: [[], [], []],
      word_count: [[], [], []],

      wordlist: {},
      wordlist_count: {},
  
      grams_order_max: 0,
      grams_order: [[], [], []],
      grams_order_count: [[], [], []],

      tags: [],
      lineByTag: {},
  
      //------------------------------------------------------------------------
      // 유형, 빈도, 비율
      num_word_tokens_full: [0, 0, 0, 0],
      num_gram_tokens_full: [0, 0, 0, 0],

      //------------------------------------------------------------------------
      // 글로벌 변수
      words_max: 0,
      total_par_word_count: 0,
    }
   
    let words = [[], [], []];
    let grams = [[], [], []];    

    const lines = text.split('\n');
    for(let i=0; i<lines.length; i++) {    
      let tmp = "";
      let tag = "";
      let par = "";
      let valid = true;
      let tagvalid = false;
      let parvalid = false;
      let par_word_count = 0;
      let statement_raw = lines[i];
      let statement = lines[i].trim();  // trim()은 문자열 양쪽 끝에 있는 공백을 제거해 줌.
      for(let j=0; j<statement.length; j++) {
        const ch = statement[j];
        if (ch === "(") {
          valid = false;
          parvalid = true;
        } else if (ch === ")") {
          valid = true;
          parvalid = false;
          par += " ";
        } else if (ch === "[") {
          valid = false;
          tagvalid = true;
          tag += ch;
        } else if (ch === "]") {
          valid = true;
          tagvalid = false;
          tag += ch;

          //태그 등록
          if (!result.tags.includes(tag)) {
            result.tags.push(tag);
          }
      
          if (!result.lineByTag[tag]) {
            result.lineByTag[tag] = [];
          }
          result.lineByTag[tag].push(statement);
      
          tag = "";
        } else {
          if (valid) {
            tmp += ch;
          }
          if (parvalid) {
            par += ch;
          }
          if (tagvalid) {
            tag += ch;
          }
        }
      }
  
      let parTokens = par.split(" ");
      for (let j = 0; j < parTokens.length; j++) {
        if (parTokens[j].length > 0) {
          par_word_count++;
        }
      }

      result.total_par_word_count += par_word_count;

      statement = tmp;

      /*
      console.log("tmp:", tmp);
      console.log("par:", par);
      console.log("tags:", tags);
      console.log("linebytag:", lineByTag);
      console.log("statement:", statement)
      */

      if (statement.length > 0) {
        //------------------------------------------------------------------
        // 발화의 종류 결정
        let lineOwnerStr = statement.substring(0, 1);
        let lineOwner;
        if (lineOwnerStr === "아") lineOwner = 0;
        else if (lineOwnerStr === "엄") lineOwner = 1;
        else if (lineOwnerStr === "검") lineOwner = 2;
        else if (lineOwnerStr === "+") lineOwner = 3;
        else lineOwner = 4;
        //------------------------------------------------------------------

        if (lineOwner === 3) {
          let infoType = statement.substring(1, 3);
          if (infoType === "이름") result.info.name = statement.substring(4).trim();
          if (infoType === "생일") result.info.birthday = statement.substring(4).trim();
          if (infoType === "성별") result.info.gender = statement.substring(4).trim();
          if (infoType === "특이") result.info.note = statement.substring(4).trim();
          infoType = statement.substring(1, 4);
          if (infoType === "검사일") result.info.examdate = statement.substring(5).trim();
          infoType = statement.substring(1, 5);
          if (infoType === "검사시간") {
            let infoTimeStr = statement.substring(6).trim();
            infoTimeStr = infoTimeStr.trim();
            let delim = 0;
            for (let j = 0; j < infoTimeStr.length; j++) {
              let ch = infoTimeStr[j];
              if (ch === "분") {
                result.info.minutes = infoTimeStr.substring(0, j);
                delim = j + 1;
                break;
              }
            }
            let infoSubstr = infoTimeStr.substring(delim).trim();
            for (let j = 0; j < infoSubstr.length; j++) {
              let ch = infoSubstr[j];
              if (ch === "초") {
                result.info.seconds = infoSubstr.substring(0, j);
                break;
              }
            }
          }
        } // if (lineOwner === 3)
        else if (lineOwner < 3) {
          //--------------------------------------------------------------
          // 원문장을 종류별로 저장
          let lineIter = result.statements[lineOwner].length;
          result.statements[lineOwner][lineIter] = statement_raw.substring(1).trim();
          //--------------------------------------------------------------

          //--------------------------------------------------------------
          // 이해 안되는 부분이 있는지 확인
          let part_known = 0;
          let part_unknown = 0;
          // statement의 길이만큼 루프를 돌면서 이해 불가 부분을 확인
          for (let j = 1; j < statement.length; j++) {
              let ch = statement[j];
              if (ch === "*") part_unknown = 1;
              else if (ch === " " || ch === "\n" || ch === "\r") { }
              else {
                  part_known = 1;
              }
          }
          // 문장에 "XXX"가 있는 경우 완전 이해 불가로 간주
          for (let j = 1; j < statement.length; j++) {
              let sub = statement.substring(j, j + 3);
              if (sub === "XXX") {
                  part_known = 0;
                  part_unknown = 1;
              }
          }
          //--------------------------------------------------------------

          //--------------------------------------------------------------
	        // 이해 가능도별 분석 데이터 얻음
          result.num_line_total[lineOwner]++;
          if (part_known === 0) {
              result.num_line_unknown[lineOwner]++;
              result.statements_understand[lineOwner][lineIter] = 0;
          }
          else {
            if (part_unknown === 0) {
              result.num_line_known[lineOwner]++;
              result.statements_understand[lineOwner][lineIter] = 2;
            }
            else {
              result.num_line_partial[lineOwner]++;
              result.statements_understand[lineOwner][lineIter] = 1;
            }
          }
          //--------------------------------------------------------------

          //--------------------------------------------------------------
	        // 마지막 글자에 따라 문장을 분류하고 마지막 글자는 제거
          statement = statement.trim();
          let lastch = statement.slice(-1);
          if (lastch === ".") {
            result.num_line_end_period[lineOwner]++;
            statement = statement.slice(0, -1);
          }
          else if (lastch === "?") {
            result.num_line_end_question[lineOwner]++;
            statement = statement.slice(0, -1);
          }
          else if (lastch === "!") {
            result.num_line_end_exclamation[lineOwner]++;
            statement = statement.slice(0, -1);
          }
          else if (lastch === ">") {
            result.num_line_end_bracket[lineOwner]++;
            statement = statement.slice(0, -1);
          }
          else if (lastch === "^") {
            result.num_line_end_exponential[lineOwner]++;
            statement = statement.slice(0, -1);
          }
          //--------------------------------------------------------------

          //--------------------------------------------------------------
          // 문장을 띄어쓰기에 따라 나눔 (토큰 생성)
          const tokens = statement.split(" ");
          let statementWithStar = 0;
          let localWords = 0;
          let localMorphemes = 0;
          result.word_count[lineOwner][lineIter] = 0;

          for (let j = 1; j < tokens.length; j++) {
            if (tokens[j].length > 0) {
              //------------------------------------------------------
              // 어절 수 저장 (이 변수는 이해 안가는 어절도 수에 포함)
              result.word_count[lineOwner][lineIter]++;
      
              //------------------------------------------------------
              // 이해 안되는 부분이 있으면 제외
              let omit = 0;
              for (let k = 0; k < tokens[j].length; k++) {
                  if (tokens[j].charAt(k) === "*") {
                      omit = 1;
                      break;
                  }
                  if (tokens[j].substring(k, k + 3) === "XXX") {
                      omit = 1;
                      break;
                  }
              }
              if (omit === 1) {
                  statementWithStar = 1;
                  continue;
              }
              //------------------------------------------------------

              result.num_word_tokens[lineOwner]++;
              localWords++;

              const morphemes = tokens[j].split("/");

              const root = morphemes[0];
              const size = result.wordlist[root] ? result.wordlist[root].length : 0;

              // 이미 있는지 확인
              let found = false;
              for (let k = 0; k < size; k++) {
                  if (result.wordlist[root][k] === tokens[j]) {
                      result.wordlist_count[root][k]++;
                      found = true;
                      break;
                  }
              }

              if (!found) {
                  if (!result.wordlist[root]) {
                      result.wordlist[root] = [];
                      result.wordlist_count[root] = [];
                  }
                  result.wordlist[root][size] = tokens[j];
                  result.wordlist_count[root][size] = 1;
              }

              //------------------------------------------------------
              // 낱말이 기존에 있었던 것인지 확인
              found = false;
              if (!words[lineOwner]) {
                  words[lineOwner] = [];
              }
              for (let k = 0; k < words[lineOwner].length; k++) {
                  if (words[lineOwner][k] === morphemes[0]) {
                      found = true;
                      break;
                  }
              }
              if (!found) {
                  const size = words[lineOwner].length;
                  words[lineOwner][size] = morphemes[0];
              }

              //------------------------------------------------------
              // 문법형태소 분석
              for (let g = 1; g < morphemes.length; g++) {
                result.num_gram_tokens[lineOwner]++;
                localMorphemes++;

                let found = false;
                if (!grams[lineOwner]) {
                    grams[lineOwner] = [];
                }
                for (let k = 0; k < grams[lineOwner].length; k++) {
                    if (grams[lineOwner][k] === morphemes[g]) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    const size = grams[lineOwner].length;
                    //console.log("어레이크기: " + size);
                    grams[lineOwner][size] = morphemes[g];
                }

                found = false;
                if (!result.grams_order[lineOwner]) {
                    result.grams_order[lineOwner] = [];
                }
                if (!result.grams_order[lineOwner][g]) {
                    result.grams_order[lineOwner][g] = [];
                }
                if (!result.grams_order_count[lineOwner]) {
                    result.grams_order_count[lineOwner] = [];
                }
                if (!result.grams_order_count[lineOwner][g]) {
                    result.grams_order_count[lineOwner][g] = [];
                }
                for (let k = 0; k < result.grams_order[lineOwner][g].length; k++) {
                    if (result.grams_order[lineOwner][g][k] === morphemes[g]) {
                        result.grams_order_count[lineOwner][g][k]++;
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    const size = result.grams_order[lineOwner][g].length;
                    result.grams_order[lineOwner][g][size] = morphemes[g];
                    result.grams_order_count[lineOwner][g][size] = 1;
                    if (g > result.grams_order_max) result.grams_order_max = g;
                }
              } // for (let g = 1; g < morphemes.length; g++) {
            } // if (tokens[j].length > 0)
          } // for (let j = 1; j < tokens.length; j++)
          
          if (statementWithStar === 0) {
            if (result.word_count[lineOwner][lineIter] > result.words_max) {
                result.words_max = result.word_count[lineOwner][lineIter];
            }
            result.num_word_tokens_full[lineOwner] += localWords;
            result.num_gram_tokens_full[lineOwner] += localMorphemes;
          }

          result.info.time = parseInt(result.info.minutes, 10) * 60 + parseInt(result.info.seconds, 10);
          console.log(result.info.time)
          console.log(result.info.minutes)
          console.log(result.info.seconds)
        } // else if (lineOwner < 3)
      } // if (statement.length > 0)
    }

    // 키를 정렬한 wordlist 객체 생성
    const sorted_wordlist= Object.keys(result.wordlist)
    .sort()
    .reduce((acc, key) => {
        acc[key] = result.wordlist[key];
        return acc;
    }, {});
    result.wordlist = sorted_wordlist;

    for (let i = 0; i < 3; i++) {
        result.num_word_types[i] = words[i].length;
        result.num_gram_types[i] = grams[i].length;
    
        //----------------------------------------------------------------------
        // 통계치 계산
        result.num_morp_types[i] = result.num_word_types[i] + result.num_gram_types[i];
        result.num_morp_tokens[i] = result.num_word_tokens[i] + result.num_gram_tokens[i];
        result.ratio_word[i] = result.num_word_types[i] / result.num_word_tokens[i];
        result.ratio_gram[i] = result.num_gram_types[i] / result.num_gram_tokens[i];
        result.ratio_morp[i] = result.num_morp_types[i] / result.num_morp_tokens[i];
        result.mlu_w[i] = result.num_word_tokens_full[i] / result.num_line_known[i];
        result.mlu_m[i] = (result.num_word_tokens_full[i] + result.num_gram_tokens_full[i]) / result.num_line_known[i];
    }
  
    //console.log(result.info)
    //console.log(wordlist)
    //console.log(sorted_wordlist)
    return result;
  }, []);

  useEffect(() => {
    if (inputText) {
      const result = analyzeText(inputText);
      setAnalysis(result);
    }
  }, [inputText, analyzeText]);

  const formatValue = (value) => {
    return isNaN(value) ? '-' : value.toFixed(2);
  };

  return (
    <div className="kla-result">
      <h2 className="page-title">발화분석결과</h2>

      <div className="panel">
        <div className="panel-heading" onClick={toggleInfoCollapse}>
          <h5 className="panel-title">아동정보</h5>
          <span className="collapse-icon">{isInfoCollapsed ? '▼' : '▲'}</span>
        </div>
        {!isInfoCollapsed && (
          <div className="panel-body">
            <table className="analysis-table">
              <tbody>
                <tr>
                  <th>이름</th>
                  <td>{analysis.info.name}</td>
                </tr>
                <tr>
                  <th>생년월일</th>
                  <td>{analysis.info.birthday}</td>
                </tr>
                <tr>
                  <th>성별</th>
                  <td>{analysis.info.gender}</td>
                </tr>
                <tr>
                  <th>특이사항</th>
                  <td>{analysis.info.note}</td>
                </tr>
                <tr>
                  <th>검사일자</th>
                  <td>{analysis.info.examdate}</td>
                </tr>
                <tr>
                  <th>검사시간</th>
                  <td>{analysis.info.minutes}분 {analysis.info.seconds}초</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="panel">
        <div className="panel-heading" onClick={toggleGrammarCollapse}>
          <h5 className="panel-title">문법 / 의미 / 발화부</h5>
          <span className="collapse-icon">{isGrammarCollapsed ? '▼' : '▲'}</span>
        </div>
        {!isGrammarCollapsed && (
          <div className="panel-body">
            <table className="analysis-table">
              <thead>
                <tr>
                  <th colSpan="4" className="active">문법</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>유형 / 빈도 / 비율</th>
                  <th className="text-primary text-right">아동</th>
                  <th className="text-right">엄마</th>
                  <th className="text-right">검사자</th>
                </tr>
                <tr>
                  <td width="55%">MLU_w (낱말 빈도수 / 총발화수)</td>
                  <td width="15%" className="text-primary text-right">{formatValue(analysis.mlu_w[0])}</td>
                  <td width="15%" className="text-right">{formatValue(analysis.mlu_w[1])}</td>
                  <td width="15%" className="text-right">{formatValue(analysis.mlu_w[2])}</td>
                </tr>
                <tr>
                  <td>MLU_m (낱말 빈도수 + 문법형태소 빈도수 / 총발화수)</td>
                  <td className="text-primary text-right">{formatValue(analysis.mlu_m[0])}</td>
                  <td className="text-right">{formatValue(analysis.mlu_m[1])}</td>
                  <td className="text-right">{formatValue(analysis.mlu_m[2])}</td>
                </tr>
                <tr>
                  <td>문법형태소 유형 수</td>
                  <td className="text-primary text-right">{analysis.num_gram_types[0]}</td>
                  <td className="text-right">{analysis.num_gram_types[1]}</td>
                  <td className="text-right">{analysis.num_gram_types[2]}</td>
                </tr>
                <tr>
                  <td>문법형태소 빈도 수</td>
                  <td className="text-primary text-right">{analysis.num_gram_tokens[0]}</td>
                  <td className="text-right">{analysis.num_gram_tokens[1]}</td>
                  <td className="text-right">{analysis.num_gram_tokens[2]}</td>
                </tr>
                <tr>
                  <td>형태소 유형 수</td>
                  <td className="text-primary text-right">{analysis.num_morp_types[0]}</td>
                  <td className="text-right">{analysis.num_morp_types[1]}</td>
                  <td className="text-right">{analysis.num_morp_types[2]}</td>
                </tr>
                <tr>
                  <td>형태소 빈도 수</td>
                  <td className="text-primary text-right">{analysis.num_morp_tokens[0]}</td>
                  <td className="text-right">{analysis.num_morp_tokens[1]}</td>
                  <td className="text-right">{analysis.num_morp_tokens[2]}</td>
                </tr>
                <tr>
                  <td>문법형태소 빈도 비율</td>
                  <td className="text-primary text-right">{formatValue(analysis.ratio_gram[0])}</td>
                  <td className="text-right">{formatValue(analysis.ratio_gram[1])}</td>
                  <td className="text-right">{formatValue(analysis.ratio_gram[2])}</td>
                </tr>
                <tr>
                  <td>형태소유형 빈도 비율</td>
                  <td className="text-primary text-right">{formatValue(analysis.ratio_morp[0])}</td>
                  <td className="text-right">{formatValue(analysis.ratio_morp[1])}</td>
                  <td className="text-right">{formatValue(analysis.ratio_morp[2])}</td>
                </tr>
              </tbody>
            </table>

            <table className="analysis-table">
              <thead>
                <tr>
                  <th colSpan="4" className="active">의미</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>유형 / 빈도 / 비율</th>
                  <th className="text-primary text-right">아동</th>
                  <th className="text-right">엄마</th>
                  <th className="text-right">검사자</th>
                </tr>
                <tr>
                  <td width="55%">낱말 유형 수</td>
                  <td width="15%" className="text-primary text-right">{analysis.num_word_types[0]}</td>
                  <td width="15%" className="text-right">{analysis.num_word_types[1]}</td>
                  <td width="15%" className="text-right">{analysis.num_word_types[2]}</td>
                </tr>
                <tr>
                  <td>낱말 빈도 수</td>
                  <td className="text-primary text-right">{analysis.num_word_tokens[0]}</td>
                  <td className="text-right">{analysis.num_word_tokens[1]}</td>
                  <td className="text-right">{analysis.num_word_tokens[2]}</td>
                </tr>
                <tr>
                  <td>낱말 유형 빈도비율</td>
                  <td className="text-primary text-right">{formatValue(analysis.ratio_word[0])}</td>
                  <td className="text-right">{formatValue(analysis.ratio_word[1])}</td>
                  <td className="text-right">{formatValue(analysis.ratio_word[2])}</td>
                </tr>
              </tbody>
            </table>
            
            <table className="analysis-table">
              <thead>
                <tr>
                  <th colSpan="4" className="active">발화부</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>이해 가능도 별 발화 수</th>
                  <th className="text-primary text-right">아동</th>
                  <th className="text-right">엄마</th>
                  <th className="text-right">검사자</th>
                </tr>
                <tr>
                  <td width="55%">전체 발화 수</td>
                  <td width="15%" className="text-primary text-right">{analysis.num_line_total[0]}</td>
                  <td width="15%" className="text-right">{analysis.num_line_total[1]}</td>
                  <td width="15%" className="text-right">{analysis.num_line_total[2]}</td>
                </tr>
                <tr>
                  <td>이해 불가</td>
                  <td className="text-primary text-right">{analysis.num_line_unknown[0]}</td>
                  <td className="text-right">{analysis.num_line_unknown[1]}</td>
                  <td className="text-right">{analysis.num_line_unknown[2]}</td>
                </tr>
                <tr>
                  <td>부분 이해 가능</td>
                  <td className="text-primary text-right">{analysis.num_line_partial[0]}</td>
                  <td className="text-right">{analysis.num_line_partial[1]}</td>
                  <td className="text-right">{analysis.num_line_partial[2]}</td>
                </tr>
                <tr>
                  <td>완전 이해 가능</td>
                  <td className="text-primary text-right">{analysis.num_line_known[0]}</td>
                  <td className="text-right">{analysis.num_line_known[1]}</td>
                  <td className="text-right">{analysis.num_line_known[2]}</td>
                </tr>
                <tr>
                  <th>이해가능 발화 유형 분석</th>
                  <th className="text-primary text-right">아동</th>
                  <th className="text-right">엄마</th>
                  <th className="text-right">검사자</th>
                </tr>
                <tr>
                  <td>전체 이해 가능한 발화</td>
                  <td className="text-primary text-right">{analysis.num_line_known[0]}</td>
                  <td className="text-right">{analysis.num_line_known[1]}</td>
                  <td className="text-right">{analysis.num_line_known[2]}</td>
                </tr>
                <tr>
                  <td>.으로 끝나는 발화</td>
                  <td className="text-primary text-right">{analysis.num_line_end_period[0]}</td>
                  <td className="text-right">{analysis.num_line_end_period[1]}</td>
                  <td className="text-right">{analysis.num_line_end_period[2]}</td>
                </tr>
                <tr>
                  <td>?으로 끝나는 발화</td>
                  <td className="text-primary text-right">{analysis.num_line_end_question[0]}</td>
                  <td className="text-right">{analysis.num_line_end_question[1]}</td>
                  <td className="text-right">{analysis.num_line_end_question[2]}</td>
                </tr>
                <tr>
                  <td>!으로 끝나는 발화</td>
                  <td className="text-primary text-right">{analysis.num_line_end_exclamation[0]}</td>
                  <td className="text-right">{analysis.num_line_end_exclamation[1]}</td>
                  <td className="text-right">{analysis.num_line_end_exclamation[2]}</td>
                </tr>
                <tr>
                  <td>&gt;으로 끝나는 발화</td>
                  <td className="text-primary text-right">{analysis.num_line_end_bracket[0]}</td>
                  <td className="text-right">{analysis.num_line_end_bracket[1]}</td>
                  <td className="text-right">{analysis.num_line_end_bracket[2]}</td>
                </tr>
                <tr>
                  <td>^으로 끝나는 발화</td>
                  <td className="text-primary text-right">{analysis.num_line_end_exponential[0]}</td>
                  <td className="text-right">{analysis.num_line_end_exponential[1]}</td>
                  <td className="text-right">{analysis.num_line_end_exponential[2]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

      <div className="panel">
        <div className="panel-heading" onClick={toggleChildStatementsCollapse}>
          <h5 className="panel-title">아동전체 발화목록</h5>
          <span className="collapse-icon">{isChildStatementsCollapsed ? '▼' : '▲'}</span>
        </div>
        {!isChildStatementsCollapsed && (
          <div className="panel-body border-bottom" style={{ borderColor: '#ddd' }}></div>
        )}
        {!isChildStatementsCollapsed && (
          <ol className="list m-20">
            {analysis.statements[0].map((statement, index) => (
              <li key={index}>{statement}</li>
            ))}
          </ol>
        )}
      </div>

      <div className="panel">
        <div className="panel-heading" onClick={toggleEojeolCollapse}>
          <h5 className="panel-title">어절수별 발화목록</h5>
          <span className="collapse-icon">{isEojeolCollapsed ? '▼' : '▲'}</span>
        </div>
        {!isEojeolCollapsed && (
          <div className="panel-body border-bottom" style={{ borderColor: '#ddd' }}></div>
        )}
        {!isEojeolCollapsed && (
          <ul className="list-unstyled m-20">
            {Array.from({ length: analysis.words_max }, (_, i) => i + 1).map((i) => (
              <li key={i} className="mb-15">
                {i}어절:<br />
                {analysis.statements[0].map((statement, j) => {
                  if (analysis.statements_understand[0][j] === 2 && analysis.word_count[0][j] === i) {
                    return (
                      <div key={j}>
                        {j + 1} {statement}
                      </div>
                    );
                  }
                  return null;
                })}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* 낱말목록 */}
      <div className="panel">
        <div className="panel-heading" onClick={toggleWordListCollapse}>
          <h5 className="panel-title">낱말목록</h5>
          <span className="collapse-icon">{isWordListCollapsed ? '▼' : '▲'}</span>
        </div>
        {!isWordListCollapsed && (
          <div className="panel-body border-bottom" style={{ borderColor: '#ddd' }}></div>
        )}
        {!isWordListCollapsed && (
          <div className="table-responsive">
            <table className="table">
              <tbody>
                {Object.keys(analysis.wordlist).map((key) => {
                  const sum = analysis.wordlist[key].reduce((acc, _, idx) => acc + analysis.wordlist_count[key][idx], 0);
                  return (
                    <React.Fragment key={key}>
                      <tr>
                        <td width="55%">{key}+</td>
                        <td width="45%">{sum}</td>
                      </tr>
                      {analysis.wordlist[key].map((word, idx) => (
                        <tr key={word}>
                          <td>{word}</td>
                          <td>{analysis.wordlist_count[key][idx]}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* 문법형태소목록 */}
      <div className="panel">
        <div className="panel-heading" onClick={toggleGrammarMorphemeListCollapse}>
          <h5 className="panel-title">문법형태소목록</h5>
          <span className="collapse-icon">{isGrammarMorphemeListCollapsed ? '▼' : '▲'}</span>
        </div>
        {!isGrammarMorphemeListCollapsed && (
          <div className="panel-body border-bottom" style={{ borderColor: '#ddd' }}></div>
        )}
        {!isGrammarMorphemeListCollapsed && (
          <div className="table-responsive">
            <table className="table">
              <tbody>
                {Array.from({ length: analysis.grams_order_max }, (_, i) => i + 1).map((i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <th colSpan="2" className="active">{i}번째 나온 문법형태소</th>
                    </tr>
                    {analysis.grams_order[0][i] && analysis.grams_order[0][i].map((gram, j) => (
                      <tr key={j}>
                        <td width="55%">{gram}</td>
                        <td width="45%">{analysis.grams_order_count[0][i][j]}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* []있는 발화목록 */}
      <div className="panel">
        <div className="panel-heading" onClick={toggleStapleListCollapse}>
          <h5 className="panel-title">[]있는 발화목록</h5>
          <span className="collapse-icon">{isStapleListCollapsed ? '▼' : '▲'}</span>
        </div>
        {!isStapleListCollapsed && (
          <div className="panel-body border-bottom" style={{ borderColor: '#ddd' }}></div>
        )}
        {!isStapleListCollapsed && (
          <ul className="list-unstyled m-20">
            {analysis.tags.map((tag, i) => (
              <li key={i} className="mb-15">
                {tag}<br />
                {analysis.lineByTag[tag].map((line, j) => (
                  <div key={j}>{line}</div>
                ))}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* 발화의 유창성 */}
      <div className="panel">
        <div className="panel-heading" onClick={toggleFluencyCollapse}>
          <h5 className="panel-title">발화의 유창성</h5>
          <span className="collapse-icon">{isFluencyCollapsed ? '▼' : '▲'}</span>
        </div>
        {!isFluencyCollapsed && (
          <div className="panel-body"></div>
        )}
        {!isFluencyCollapsed && (
          <div className="table-responsive">
            <table className="table">
              <tbody>
                <tr>
                  <td width="55%">분당 형태소수</td>
                  <td width="45%">{analysis.info.time > 0 ? formatValue((analysis.num_morp_tokens[0] * 60) / analysis.info.time) : '-'}</td>
                </tr>
                <tr>
                  <td width="55%">분당 단어수</td>
                  <td width="45%">{analysis.info.time > 0 ? formatValue((analysis.num_word_tokens[0] * 60) / analysis.info.time) : '-'}</td>
                </tr>
                <tr>
                  <td width="55%">분당 ( )에 포함 단어 수</td>
                  <td width="45%">{analysis.info.time > 0 ? formatValue((analysis.total_par_word_count * 60) / analysis.info.time) : '-'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

    </div>
  );
}

export default KLAResult;
