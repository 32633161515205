// src/components/KLA/KLAInput.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './KLAInput.css';

function KLAInput() {
  const [inputText, setInputText] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/kla/result', { state: { inputText } });
  };

  return (
    <div>
      <div className="panel">
        <div className="panel-heading">
          <h5 className="panel-title">전사데이터</h5>
        </div>
        <div className="panel-body">
          <p>여기에 전사데이터를 입력하고 분석 버튼을 누르세요.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <textarea
                className="form-control"
                id="transcriptionData"
                rows="20"
                placeholder="여기에 전사데이터를 입력하세요."
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
              ></textarea>
              <button type="submit" className="btn btn-primary">
                분석합니다 <span className="fa fa-arrow-right"></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default KLAInput;
