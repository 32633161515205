// src/components/KLA/KLA.js
import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { FaHome, FaPen, FaList } from 'react-icons/fa';
import KLAIntro from './KLAIntro';
import KLAInput from './KLAInput';
import KLAResult from './KLAResult';
import './KLA.css';

function KLA() {
  return (
    <div className="kla-container">
      <div className="sidebar">
        <ul>
          <li>
            <Link to="/kla/method1"><FaHome /> 소개 및 분석방법</Link>
          </li>
          <li>
            <Link to="/kla/method2"><FaPen /> 신규발화분석</Link>
          </li>
          <li>
            <Link to="/kla/method3"><FaList /> 분석결과조회</Link>
          </li>
        </ul>
      </div>
      <div className="content">
        <Routes>
          <Route path="/" element={<KLAIntro />} />
          <Route path="/method1" element={<KLAIntro />} />
          <Route path="/method2" element={<KLAInput />} />
          <Route path="/method3" element={<div><h2>분석 결과 조회</h2><p>페이지 준비중입니다.</p></div>} />
          <Route path="/result" element={<KLAResult />} />
        </Routes>
      </div>
    </div>
  );
}

export default KLA;
