// src/components/KLA/KLAIntro.js
import React from 'react';
import './KLAIntro.css'; // 스타일을 적용하기 위해 CSS 파일을 임포트

function KLAIntro() {
  return (
    <div>
      <div className="panel">
        <div className="panel-heading">
          <h5 className="panel-title">KLA (Korean Language Analysis) 프로그램 소개</h5>
        </div>
        <div className="panel-body">
          <p className="pt-20 pl-10 pr-10 text-muted">
            KLA는 한림대학교 언어병리학과와 컴퓨터공학과 교수진이 공동연구 개발한 한국어 발화 분석 프로그램입니다. KLA는 사용자가 직접 전사한 자료를 토대로 평균발화길이, 어휘 다양도, 어절수별 발화목록, 낱말 및 문법형태소 목록, 관심영역 목록 등의 객관적인 분석치를 빠른 시간 내에 제공합니다. 이들 결과를 토대로 의미, 문법과 관련하여 보다 객관적이고 신뢰로운 평가가 가능할 것으로 기대합니다. 사용자께서는 입력한 발화 자료의 분석 결과를 보고서 형식으로 받아보실 수 있으며 본 프로그램을 통해 분석 자료를 관리하실 수도 있습니다.
          </p>
        </div>
      </div>

      <div className="panel">
        <div className="panel-heading">
          <h5 className="panel-title">KLA 약술 및 샘플소개</h5>
        </div>
        <div className="panel-body">
          <p className="pt-20 pl-10 pr-10 text-muted">
            대상자 또는 상대 대화자의 발화를 전사한 후 어절과 문법형태소 경계를 나누어주면, KLA가 자동적으로 분석결과를 알려줍니다. 낱말은 띄어쓰기 경계로, 문법형태소는 /로 표시합니다.
          </p>
          <ol>
            <li className="mb-10">아동에 관한 정보(이름, 생년월일, 성별, 특이사항, 검사날짜)를 아래 샘플과 같이 적습니다.</li>
            <li className="mb-10">모든 아동의 발화의 맨 앞에는 “아”라고 표시합니다. 검사자나 엄마의 경우 “검”, “엄” 이라고 시작한 후 발화를 적습니다.</li>
            <li className="mb-10">어절은 띄어쓰기로, 문법형태소는 / 로 표시합니다. 어절과 문법형태소 경계는 사용자가 판단하여 띄어쓰기 또는 /로 코딩합니다.</li>
            <li className="mb-10">이해가 불가능한 음절은 ***로 표시하면 됩니다. (아래 샘플 참조)</li>
            <li className="mb-10">발화에 [ ]를 활용하여 태그를 붙이면 분석 시에 같은 태그가 붙은 발화를 묶어서 살펴볼 수 있습니다.</li>
            <li className="mb-10">발화의 종류를 나누어 보여줍니다: 평서(.), 의문(?), 감탄(!) &gt; (겹침), ^(끊어짐)</li>
            <li className="mb-10">낱말(명사, 동사, 형용사, 부사, 의존명사)과 문법형태소(조사와 어미)가 사용된 빈도를 알려주고, 낱말, 문법형태소, 형태소(낱말+문법형태소)의 빈도를 알려줍니다. 서로 다른 유형수와 총 사용빈도수로 비율도 알려줍니다.</li>
            <li className="mb-10">낱말 리스트: 각 낱말이 어떻게 문법형태소들과 결합되는 지 빈도와 함께 가나다 순으로 알려줍니다.</li>
            <li className="mb-10">문법형태소 리스트: 문법형태소이 어절과 결합하여 나타난 위치 수에 따라 리스트들을 보여줍니다.</li>
          </ol>
          <br /><br />
          발화 전사 자료 샘플<br /><br />
          <div className="well">
            <dl>
              <dd>+이름 김예은</dd>
              <dd>+생일 2010.12.10</dd>
              <dd>+성별 여</dd>
              <dd>+특이 앞니가 빠져있어서 '시' 발음이 잘 안됨.</dd>
              <dd>+검사일 2016.01.25</dd>
              <dd>+검사시간 5분20초</dd>
              <dd>&nbsp;</dd>
              <dd>아 선생님 이 나무 끼우/는 거/ㄴ/요 [실음][실의]</dd>
              <dd>아 나무 끼우* **</dd>
              <dd>아 이렇게 되우는 [실형] 나무 어디 가/ㅆ/지 [실형]?</dd>
              <dd>아 (우와) 난초/다</dd>
              <dd>아 선생님 이거 난초/(예)요?</dd>
              <dd>아 화분 [실음][실의]</dd>
              <dd>아 (와) 계단/이다</dd>
              <dd>아 (와) 미끄럼틀/이다</dd>
              <dd>아 (와) 원숭이/다</dd>
              <dd>아 나 얘/가 [실형] 태워 줘/ㅆ/(어)요</dd>
              <dd>아 요 원숭이.</dd>
              <dd>아 네.</dd>
              <dd>아 우리 아빠 (우리) 말 타/ㅆ/는데 [실화].</dd>
              <dd>아 얘 누구/(예)요 선생님?</dd>
              <dd>아 왜 한 짝/이 없/지 신발/이&gt;</dd>
              <dd>아 (아) 요거 똑같/은 거 있/었/는데.</dd>
              <dd>아 없/었/는데 [실형].</dd>
              <dd>아 우리 누나/가 다 버려/ㅆ/는데.</dd>
              <dd>아 네.</dd>
              <dd>아 버려/ㅆ/잖아/요 [실화].</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KLAIntro;
