// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Introduction from './components/Introduction';
import KLA from './components/KLA/KLA';
import Questionnaire from './components/Questionnaire';

function App() {
  return (
    <Router>
      <div className="app-container">
        <header>
          <nav>
            <div className="left-menu">
              <h2><Link to="/">Speech4All</Link></h2>
            </div>
            <div className="right-menu">
              <h2><Link to="/kla">KLA</Link></h2>
              <h2><Link to="/questionnaire">언어기반 문해기초선별</Link></h2>
            </div>
          </nav>
        </header>
        <div className="main-container">
          <Routes>
              <Route path="/" element={<Introduction />} />
              <Route path="/introduction" element={<Introduction />} />
              <Route path="/kla/*" element={<KLA />} />
              <Route path="/questionnaire" element={<Questionnaire />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
